<template>
  <EntityTable
    :api-url="apiUrl"
    :columns="columns"
    :title="title"
    :edit-page="editPage"
    :delete-url="deleteUrl"
    edit-page-router="property"
  ></EntityTable>
</template>
<script>
import { defineComponent } from "vue";
import EntityTable from "@/components/EntityTable";

export default defineComponent({
  name: "properties",
  components: {
    EntityTable,
  },
  data: function () {
    return {
      apiUrl: "admin/property",
      deleteUrl: "api/admin/property/delete/",

      editPage: "/admin-dashboard/property/",
      title: "Свойства",
      columns: [
        {
          name: "Name",
          value: "name",
        },
        {
          name: "Value",
          value: "value",
        },
      ],
    };
  },

  methods: {},
});
</script>
